.libraryCards .p-card .p-card-body {
    padding: 0;
}

.clickableCarouselDiv {
    transition: background-color 0.4s ease, transform 0.4s ease;
}

.clickableCarouselDiv:hover {
    cursor: pointer;
    transform: scale(1.01);
    background-color: #2D3967 !important;
}

.clickableCarouselDiv:hover p {
    color: white !important;
}

.libraryAccordion .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0.5rem;
    border: 1px solid #BEC2D0;
    color: #2D3967;
    background: #ffffff;
    border-radius: 10px;
}

.libraryAccordion .p-accordion .p-accordion-content {
    padding: 0.5rem;
    border: 1px solid #BEC2D0;
    background: #F7F8FA;
    color: #4b5563;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

