.code-dialog .p-dialog-content {
    padding: 0 !important;
    border-radius: 24px;
    overflow: hidden;
}

.code-dialog {
    max-height: 90vh;
}

.code-dialog .p-dialog-header {
    display: none;
}

.accessCode .p-inputtext {

    color: #ffffff;
    background: transparent;
    padding: 0.75rem 0.75rem;
    height: 2.5rem;
    width: 2.5rem;
    border: 1px solid #ECFAF5;
    border-radius: 12px;
  }

.accessCode .p-inputtext:enabled:hover {
    border-color: #23CF95;
  }