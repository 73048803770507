/* MenuBar CSS*/
.p-menubar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-menubar .p-menubar-root-list {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

@media screen and (max-width: 1385px) {
    .p-menubar {
        position: relative;
    }

    .p-menubar .p-menubar-button {
        display: flex;
        width: 2rem;
        height: 2rem;
        color: #ffffff;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-menubar .p-menubar-button:hover {
        color: #6b7280;
        background: #f3f4f6;
    }

    .p-menubar .p-menubar-button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #a5f3fc;
    }

    .p-menubar .p-menubar-root-list {
        position: absolute;
        display: none;
        padding: 0.5rem 0;
        background: #ffffff;
        border: 0 none;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        width: 100%;
    }

    .p-menubar .p-menubar-root-list .p-menu-separator {
        border-top: 1px solid #e5e7eb;
        margin: 0.25rem 0;
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon {
        font-size: 0.875rem;
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon.p-icon {
        width: 0.875rem;
        height: 0.875rem;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem {
        width: 100%;
        position: static;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-menuitem-content {
        color: #4b5563;
        transition: box-shadow 0.2s;
        border-radius: 0;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-menuitem-content .p-menuitem-link {
        color: #4b5563;
        padding: 0.75rem 1.25rem;
        user-select: none;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #4b5563;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
        color: #6b7280;
        margin-right: 0.5rem;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link.p-highlight>.p-menuitem-content {
        color: #0e7490;
        background: #ecfeff;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #0e7490;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #0e7490;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link.p-highlight.p-focus>.p-menuitem-content {
        background: rgba(6, 182, 212, 0.24);
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
        color: #4b5563;
        background: #e5e7eb;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: #4b5563;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
        color: #4b5563;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
        color: #4b5563;
        background: #f3f4f6;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
        color: #4b5563;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
        color: #6b7280;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-submenu-icon {
        margin-left: auto;
        transition: transform 0.2s;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link>.p-submenu-icon {
        transform: rotate(-180deg);
    }

    .p-menubar .p-menubar-root-list .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none;
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
        transition: transform 0.2s;
        transform: rotate(90deg);
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active>.p-menuitem-link>.p-submenu-icon {
        transform: rotate(-90deg);
    }

    .p-menubar .p-menubar-root-list .p-menuitem {
        width: 100%;
        position: static;
    }

    .p-menubar .p-menubar-root-list ul li a {
        padding-left: 2.25rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li a {
        padding-left: 3.75rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li a {
        padding-left: 5.25rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
        padding-left: 6.75rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
        padding-left: 8.25rem;
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        z-index: 1;
    }
}

.p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
    cursor: pointer;
    transform: scale(1.01);
    transition: transform 0.3s ease;
}

.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
    background: #FFFFFF;
    font-weight: bold;
}

.p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #E3506B;
    font-weight: bold;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content {
    border-radius: 10px;
    background-color: #374474;
    margin: 0.3rem;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #FFFFFF;
    font-weight: bold;
}

.p-menubar .p-menubar-root-list {
    background-color: #2D3967;
}


/* MenuBar Language Dropdown CSS*/
.globalDropdown.p-dropdown {
    background: #2D3967;
    border: none;
}

.globalDropdown.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.1rem #2D3967;
    border-color: #2D3967;
}

.globalDropdown.p-dropdown .p-dropdown-trigger {
    color: #FFFFFF;
    width: 1rem;

}


/* MenuBar Split Button CSS*/
.split-button-main {
    pointer-events: none;
    display: none;
    opacity: 0.5;
}

.p-splitbutton.p-button-secondary.p-button-text>.p-button {
    color: #FFFFFF;
}

.p-splitbutton.p-button-secondary.p-button-text>.p-button:not(:disabled):hover {
    background: #2D3967;
    color: #FFFFFF;
}